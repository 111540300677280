// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-100-cashback-tsx": () => import("./../../../src/pages/100cashback.tsx" /* webpackChunkName: "component---src-pages-100-cashback-tsx" */),
  "component---src-pages-18-mesyacev-media-tsx": () => import("./../../../src/pages/18-mesyacev-media.tsx" /* webpackChunkName: "component---src-pages-18-mesyacev-media-tsx" */),
  "component---src-pages-24-mesyaca-bez-procentov-index-tsx": () => import("./../../../src/pages/24-mesyaca-bez-procentov/index.tsx" /* webpackChunkName: "component---src-pages-24-mesyaca-bez-procentov-index-tsx" */),
  "component---src-pages-24-mesyaca-bez-procentov-media-tsx": () => import("./../../../src/pages/24-mesyaca-bez-procentov/media.tsx" /* webpackChunkName: "component---src-pages-24-mesyaca-bez-procentov-media-tsx" */),
  "component---src-pages-24-mesyaca-bez-procentov-promo-media-tsx": () => import("./../../../src/pages/24-mesyaca-bez-procentov-promo/media.tsx" /* webpackChunkName: "component---src-pages-24-mesyaca-bez-procentov-promo-media-tsx" */),
  "component---src-pages-24-mesyaca-refinansirovanie-media-tsx": () => import("./../../../src/pages/24-mesyaca-refinansirovanie-media.tsx" /* webpackChunkName: "component---src-pages-24-mesyaca-refinansirovanie-media-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adamas-tsx": () => import("./../../../src/pages/adamas.tsx" /* webpackChunkName: "component---src-pages-adamas-tsx" */),
  "component---src-pages-approved-tsx": () => import("./../../../src/pages/approved.tsx" /* webpackChunkName: "component---src-pages-approved-tsx" */),
  "component---src-pages-backtoschool-auto-tsx": () => import("./../../../src/pages/backtoschool-auto.tsx" /* webpackChunkName: "component---src-pages-backtoschool-auto-tsx" */),
  "component---src-pages-backtoschool-promo-tsx": () => import("./../../../src/pages/backtoschool-promo.tsx" /* webpackChunkName: "component---src-pages-backtoschool-promo-tsx" */),
  "component---src-pages-backtoschool-tsx": () => import("./../../../src/pages/backtoschool.tsx" /* webpackChunkName: "component---src-pages-backtoschool-tsx" */),
  "component---src-pages-besthalva-tsx": () => import("./../../../src/pages/besthalva.tsx" /* webpackChunkName: "component---src-pages-besthalva-tsx" */),
  "component---src-pages-card-procent-na-ostatok-tsx": () => import("./../../../src/pages/card-procent-na-ostatok.tsx" /* webpackChunkName: "component---src-pages-card-procent-na-ostatok-tsx" */),
  "component---src-pages-cards-new-sticker-pay-tsx": () => import("./../../../src/pages/cards/new-sticker-pay.tsx" /* webpackChunkName: "component---src-pages-cards-new-sticker-pay-tsx" */),
  "component---src-pages-choose-locality-tsx": () => import("./../../../src/pages/choose-locality.tsx" /* webpackChunkName: "component---src-pages-choose-locality-tsx" */),
  "component---src-pages-choose-pickup-or-delivery-tsx": () => import("./../../../src/pages/choose-pickup-or-delivery.tsx" /* webpackChunkName: "component---src-pages-choose-pickup-or-delivery-tsx" */),
  "component---src-pages-dachnii-sezon-v-lenta-tsx": () => import("./../../../src/pages/dachnii-sezon-v-lenta.tsx" /* webpackChunkName: "component---src-pages-dachnii-sezon-v-lenta-tsx" */),
  "component---src-pages-delivery-redesign-tsx": () => import("./../../../src/pages/deliveryRedesign.tsx" /* webpackChunkName: "component---src-pages-delivery-redesign-tsx" */),
  "component---src-pages-elektronika-v-rassrochku-tsx": () => import("./../../../src/pages/elektronika-v-rassrochku.tsx" /* webpackChunkName: "component---src-pages-elektronika-v-rassrochku-tsx" */),
  "component---src-pages-employment-redesign-tsx": () => import("./../../../src/pages/employmentRedesign.tsx" /* webpackChunkName: "component---src-pages-employment-redesign-tsx" */),
  "component---src-pages-error-list-tsx": () => import("./../../../src/pages/error-list.tsx" /* webpackChunkName: "component---src-pages-error-list-tsx" */),
  "component---src-pages-error-reject-tsx": () => import("./../../../src/pages/error-reject.tsx" /* webpackChunkName: "component---src-pages-error-reject-tsx" */),
  "component---src-pages-error-repeat-tsx": () => import("./../../../src/pages/error-repeat.tsx" /* webpackChunkName: "component---src-pages-error-repeat-tsx" */),
  "component---src-pages-error-technical-tsx": () => import("./../../../src/pages/error-technical.tsx" /* webpackChunkName: "component---src-pages-error-technical-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-esia-test-tsx": () => import("./../../../src/pages/esia-test.tsx" /* webpackChunkName: "component---src-pages-esia-test-tsx" */),
  "component---src-pages-finish-tsx": () => import("./../../../src/pages/finish.tsx" /* webpackChunkName: "component---src-pages-finish-tsx" */),
  "component---src-pages-frame-form-order-frame-tsx": () => import("./../../../src/pages/frame/form-order-frame.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-tsx" */),
  "component---src-pages-frame-index-tsx": () => import("./../../../src/pages/frame/index.tsx" /* webpackChunkName: "component---src-pages-frame-index-tsx" */),
  "component---src-pages-halva-auracard-tsx": () => import("./../../../src/pages/halva-auracard.tsx" /* webpackChunkName: "component---src-pages-halva-auracard-tsx" */),
  "component---src-pages-halva-media-index-tsx": () => import("./../../../src/pages/halva/media/index.tsx" /* webpackChunkName: "component---src-pages-halva-media-index-tsx" */),
  "component---src-pages-halva-media-tsx": () => import("./../../../src/pages/halva-media.tsx" /* webpackChunkName: "component---src-pages-halva-media-tsx" */),
  "component---src-pages-halva-planetacard-tsx": () => import("./../../../src/pages/halva-planetacard.tsx" /* webpackChunkName: "component---src-pages-halva-planetacard-tsx" */),
  "component---src-pages-halva-qr-tsx": () => import("./../../../src/pages/halva-qr.tsx" /* webpackChunkName: "component---src-pages-halva-qr-tsx" */),
  "component---src-pages-halva-refinansirovanie-kreditnoy-karty-media-tsx": () => import("./../../../src/pages/halva/refinansirovanie-kreditnoy-karty-media.tsx" /* webpackChunkName: "component---src-pages-halva-refinansirovanie-kreditnoy-karty-media-tsx" */),
  "component---src-pages-halva-special-tsx": () => import("./../../../src/pages/halva-special.tsx" /* webpackChunkName: "component---src-pages-halva-special-tsx" */),
  "component---src-pages-halvenok-tsx": () => import("./../../../src/pages/halvenok.tsx" /* webpackChunkName: "component---src-pages-halvenok-tsx" */),
  "component---src-pages-happy-birthday-tsx": () => import("./../../../src/pages/happy-birthday.tsx" /* webpackChunkName: "component---src-pages-happy-birthday-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invitefriend-promo-tsx": () => import("./../../../src/pages/invitefriend-promo.tsx" /* webpackChunkName: "component---src-pages-invitefriend-promo-tsx" */),
  "component---src-pages-invitefriend-tsx": () => import("./../../../src/pages/invitefriend.tsx" /* webpackChunkName: "component---src-pages-invitefriend-tsx" */),
  "component---src-pages-ivi-tsx": () => import("./../../../src/pages/ivi.tsx" /* webpackChunkName: "component---src-pages-ivi-tsx" */),
  "component---src-pages-karta-svoboda-tsx": () => import("./../../../src/pages/karta-svoboda.tsx" /* webpackChunkName: "component---src-pages-karta-svoboda-tsx" */),
  "component---src-pages-kreditnaya-karta-halva-tsx": () => import("./../../../src/pages/kreditnaya-karta-halva.tsx" /* webpackChunkName: "component---src-pages-kreditnaya-karta-halva-tsx" */),
  "component---src-pages-letnjaja-odezhda-tsx": () => import("./../../../src/pages/letnjaja-odezhda.tsx" /* webpackChunkName: "component---src-pages-letnjaja-odezhda-tsx" */),
  "component---src-pages-list-obratnoj-svyazi-tsx": () => import("./../../../src/pages/list-obratnoj-svyazi.tsx" /* webpackChunkName: "component---src-pages-list-obratnoj-svyazi-tsx" */),
  "component---src-pages-loading-tsx": () => import("./../../../src/pages/loading.tsx" /* webpackChunkName: "component---src-pages-loading-tsx" */),
  "component---src-pages-long-form-success-not-courier-tsx": () => import("./../../../src/pages/long-form-success-not-courier.tsx" /* webpackChunkName: "component---src-pages-long-form-success-not-courier-tsx" */),
  "component---src-pages-long-form-success-tsx": () => import("./../../../src/pages/long-form-success.tsx" /* webpackChunkName: "component---src-pages-long-form-success-tsx" */),
  "component---src-pages-media-d-tsx": () => import("./../../../src/pages/media-d.tsx" /* webpackChunkName: "component---src-pages-media-d-tsx" */),
  "component---src-pages-media-halvacard-frame-tsx": () => import("./../../../src/pages/media-halvacard-frame.tsx" /* webpackChunkName: "component---src-pages-media-halvacard-frame-tsx" */),
  "component---src-pages-menyaem-mir-s-halvoi-tsx": () => import("./../../../src/pages/menyaem-mir-s-halvoi.tsx" /* webpackChunkName: "component---src-pages-menyaem-mir-s-halvoi-tsx" */),
  "component---src-pages-nedeli-umnyh-rassrochek-tsx": () => import("./../../../src/pages/nedeli-umnyh-rassrochek.tsx" /* webpackChunkName: "component---src-pages-nedeli-umnyh-rassrochek-tsx" */),
  "component---src-pages-non-gtm-tsx": () => import("./../../../src/pages/non-gtm.tsx" /* webpackChunkName: "component---src-pages-non-gtm-tsx" */),
  "component---src-pages-odezhda-v-rassrochku-tsx": () => import("./../../../src/pages/odezhda-v-rassrochku.tsx" /* webpackChunkName: "component---src-pages-odezhda-v-rassrochku-tsx" */),
  "component---src-pages-passport-redesign-tsx": () => import("./../../../src/pages/passportRedesign.tsx" /* webpackChunkName: "component---src-pages-passport-redesign-tsx" */),
  "component---src-pages-pkw-2-tsx": () => import("./../../../src/pages/pkw-2.tsx" /* webpackChunkName: "component---src-pages-pkw-2-tsx" */),
  "component---src-pages-pkw-aviasales-tsx": () => import("./../../../src/pages/pkw/aviasales.tsx" /* webpackChunkName: "component---src-pages-pkw-aviasales-tsx" */),
  "component---src-pages-pkw-black-friday-halva-media-tsx": () => import("./../../../src/pages/pkw/black-friday-halva/media.tsx" /* webpackChunkName: "component---src-pages-pkw-black-friday-halva-media-tsx" */),
  "component---src-pages-pkw-black-friday-halva-pkw-index-tsx": () => import("./../../../src/pages/pkw/black-friday-halva-pkw/index.tsx" /* webpackChunkName: "component---src-pages-pkw-black-friday-halva-pkw-index-tsx" */),
  "component---src-pages-pkw-black-friday-halva-tsx": () => import("./../../../src/pages/pkw/black-friday-halva.tsx" /* webpackChunkName: "component---src-pages-pkw-black-friday-halva-tsx" */),
  "component---src-pages-pkw-bonus-1500-tsx": () => import("./../../../src/pages/pkw/bonus-1500.tsx" /* webpackChunkName: "component---src-pages-pkw-bonus-1500-tsx" */),
  "component---src-pages-pkw-dacha-tsx": () => import("./../../../src/pages/pkw-dacha.tsx" /* webpackChunkName: "component---src-pages-pkw-dacha-tsx" */),
  "component---src-pages-pkw-gender-action-tsx": () => import("./../../../src/pages/pkw/gender-action.tsx" /* webpackChunkName: "component---src-pages-pkw-gender-action-tsx" */),
  "component---src-pages-pkw-halva-dlya-gejmerov-tsx": () => import("./../../../src/pages/pkw/halva-dlya-gejmerov.tsx" /* webpackChunkName: "component---src-pages-pkw-halva-dlya-gejmerov-tsx" */),
  "component---src-pages-pkw-halva-s-kehshbehkom-tsx": () => import("./../../../src/pages/pkw/halva-s-kehshbehkom.tsx" /* webpackChunkName: "component---src-pages-pkw-halva-s-kehshbehkom-tsx" */),
  "component---src-pages-pkw-halva-tsx": () => import("./../../../src/pages/pkw-halva.tsx" /* webpackChunkName: "component---src-pages-pkw-halva-tsx" */),
  "component---src-pages-pkw-index-tsx": () => import("./../../../src/pages/pkw/index.tsx" /* webpackChunkName: "component---src-pages-pkw-index-tsx" */),
  "component---src-pages-pkw-kiberponedelnik-tsx": () => import("./../../../src/pages/pkw/kiberponedelnik.tsx" /* webpackChunkName: "component---src-pages-pkw-kiberponedelnik-tsx" */),
  "component---src-pages-pkw-krasota-cb-tsx": () => import("./../../../src/pages/pkw/krasota_cb.tsx" /* webpackChunkName: "component---src-pages-pkw-krasota-cb-tsx" */),
  "component---src-pages-pkw-media-marketplace-tsx": () => import("./../../../src/pages/pkw/media-marketplace.tsx" /* webpackChunkName: "component---src-pages-pkw-media-marketplace-tsx" */),
  "component---src-pages-pkw-mir-tsx": () => import("./../../../src/pages/pkw/mir.tsx" /* webpackChunkName: "component---src-pages-pkw-mir-tsx" */),
  "component---src-pages-pkw-novyi-god-halva-index-tsx": () => import("./../../../src/pages/pkw/novyi-god-halva/index.tsx" /* webpackChunkName: "component---src-pages-pkw-novyi-god-halva-index-tsx" */),
  "component---src-pages-pkw-novyi-god-halva-media-tsx": () => import("./../../../src/pages/pkw/novyi-god-halva/media.tsx" /* webpackChunkName: "component---src-pages-pkw-novyi-god-halva-media-tsx" */),
  "component---src-pages-pkw-ozon-tsx": () => import("./../../../src/pages/pkw/ozon.tsx" /* webpackChunkName: "component---src-pages-pkw-ozon-tsx" */),
  "component---src-pages-pkw-perfom-tsx": () => import("./../../../src/pages/pkw/perfom.tsx" /* webpackChunkName: "component---src-pages-pkw-perfom-tsx" */),
  "component---src-pages-pkw-pir-na-volge-tsx": () => import("./../../../src/pages/pkw/pir-na-volge.tsx" /* webpackChunkName: "component---src-pages-pkw-pir-na-volge-tsx" */),
  "component---src-pages-pkw-promopages-tsx": () => import("./../../../src/pages/pkw/promopages.tsx" /* webpackChunkName: "component---src-pages-pkw-promopages-tsx" */),
  "component---src-pages-pkw-vk-cashback-ipp-tsx": () => import("./../../../src/pages/pkw/vk-cashback-ipp.tsx" /* webpackChunkName: "component---src-pages-pkw-vk-cashback-ipp-tsx" */),
  "component---src-pages-pkw-vk-cashback-tsx": () => import("./../../../src/pages/pkw/vk-cashback.tsx" /* webpackChunkName: "component---src-pages-pkw-vk-cashback-tsx" */),
  "component---src-pages-pkw-vk-education-tsx": () => import("./../../../src/pages/pkw/vk-education.tsx" /* webpackChunkName: "component---src-pages-pkw-vk-education-tsx" */),
  "component---src-pages-platezhnii-stiker-pay-tsx": () => import("./../../../src/pages/platezhnii-stiker-pay.tsx" /* webpackChunkName: "component---src-pages-platezhnii-stiker-pay-tsx" */),
  "component---src-pages-private-halva-tsx": () => import("./../../../src/pages/private-halva.tsx" /* webpackChunkName: "component---src-pages-private-halva-tsx" */),
  "component---src-pages-procenty-vernet-magazin-tsx": () => import("./../../../src/pages/procenty-vernet-magazin.tsx" /* webpackChunkName: "component---src-pages-procenty-vernet-magazin-tsx" */),
  "component---src-pages-rassrochka-na-avto-tsx": () => import("./../../../src/pages/rassrochka-na-avto.tsx" /* webpackChunkName: "component---src-pages-rassrochka-na-avto-tsx" */),
  "component---src-pages-rassrochka-na-ehlektroniku-tsx": () => import("./../../../src/pages/rassrochka-na-ehlektroniku.tsx" /* webpackChunkName: "component---src-pages-rassrochka-na-ehlektroniku-tsx" */),
  "component---src-pages-rassrochka-s-halvoi-tsx": () => import("./../../../src/pages/rassrochka-s-halvoi.tsx" /* webpackChunkName: "component---src-pages-rassrochka-s-halvoi-tsx" */),
  "component---src-pages-remont-v-rassrochku-tsx": () => import("./../../../src/pages/remont-v-rassrochku.tsx" /* webpackChunkName: "component---src-pages-remont-v-rassrochku-tsx" */),
  "component---src-pages-rostics-tsx": () => import("./../../../src/pages/rostics.tsx" /* webpackChunkName: "component---src-pages-rostics-tsx" */),
  "component---src-pages-shapka-tsx": () => import("./../../../src/pages/shapka.tsx" /* webpackChunkName: "component---src-pages-shapka-tsx" */),
  "component---src-pages-shuba-tsx": () => import("./../../../src/pages/shuba.tsx" /* webpackChunkName: "component---src-pages-shuba-tsx" */),
  "component---src-pages-success-apply-tsx": () => import("./../../../src/pages/success-apply.tsx" /* webpackChunkName: "component---src-pages-success-apply-tsx" */),
  "component---src-pages-success-list-tsx": () => import("./../../../src/pages/success-list.tsx" /* webpackChunkName: "component---src-pages-success-list-tsx" */),
  "component---src-pages-success-pkw-tsx": () => import("./../../../src/pages/success-pkw.tsx" /* webpackChunkName: "component---src-pages-success-pkw-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-tovary-dlya-doma-v-rassrochku-tsx": () => import("./../../../src/pages/tovary-dlya-doma-v-rassrochku.tsx" /* webpackChunkName: "component---src-pages-tovary-dlya-doma-v-rassrochku-tsx" */),
  "component---src-pages-umnye-nedeli-tsx": () => import("./../../../src/pages/umnye-nedeli.tsx" /* webpackChunkName: "component---src-pages-umnye-nedeli-tsx" */),
  "component---src-pages-vam-odobrena-karta-rassrochki-tsx": () => import("./../../../src/pages/vam-odobrena-karta-rassrochki.tsx" /* webpackChunkName: "component---src-pages-vam-odobrena-karta-rassrochki-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */),
  "component---src-pages-vostochniy-tsx": () => import("./../../../src/pages/vostochniy.tsx" /* webpackChunkName: "component---src-pages-vostochniy-tsx" */),
  "component---src-pages-welcome-hippo-tsx": () => import("./../../../src/pages/welcome-hippo.tsx" /* webpackChunkName: "component---src-pages-welcome-hippo-tsx" */),
  "component---src-pages-your-halva-qr-tsx": () => import("./../../../src/pages/your-halva-qr.tsx" /* webpackChunkName: "component---src-pages-your-halva-qr-tsx" */),
  "component---src-pages-zapravka-v-rassrochku-tsx": () => import("./../../../src/pages/zapravka-v-rassrochku.tsx" /* webpackChunkName: "component---src-pages-zapravka-v-rassrochku-tsx" */),
  "component---src-pages-zarplatnaya-karta-halva-tsx": () => import("./../../../src/pages/zarplatnaya-karta-halva.tsx" /* webpackChunkName: "component---src-pages-zarplatnaya-karta-halva-tsx" */),
  "component---src-pages-zoon-tsx": () => import("./../../../src/pages/zoon.tsx" /* webpackChunkName: "component---src-pages-zoon-tsx" */),
  "component---src-templates-seo-tsx": () => import("./../../../src/templates/seo.tsx" /* webpackChunkName: "component---src-templates-seo-tsx" */)
}

